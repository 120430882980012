module.exports = {
  siteTitle: 'Bez Frke', // <title>
  manifestName: 'Bez Frke',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Bez Frke',
  subHeading:
    'Online servis za naručivanje i oglašavanje usluga. Plaćanje se vrši kreditnom karticom. Poslodavci su isplaćeni automatski nakon izvršenja usluge. Mi obezbeđujemo sigurnost i jednostavnost poslovanja. Uskoro u Srbiji!',
  socialLinks: [
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: '#',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: '#',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:nenadkostic@gmail.com',
    },
  ],
};
